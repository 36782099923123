// App
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

/* Fonts */
    /* Fredoka */
    @import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300..700&display=swap');

    /* Fraunces */
    @import url('https://fonts.googleapis.com/css2?family=Fraunces:ital,opsz,wght@0,9..144,100..900;1,9..144,100..900&display=swap');

// Base colors - Colors palette
$red: #F25260;
$blue: #38A6A6;
$green: #8BBF73;
$yellow: #F2BB16;
$orange: #F28D35;

// Other colors
$gray: #EFF0F2;

// Medium opacity of base colors
$orange-medium-opacity: #F2B885;
$blue-medium-opacity: #A9D9D4;
$red-medium-opacity: #F299A9;
$yellow-medium-opacity: #F7D673;

// Low opacity of base colors
$orange-low-opacity: #FBDFC6;
$blue-low-opacity: #AFDBDB;
$red-low-opacity: #F299A9;
$yellow-low-opacity: #FBEBB9;

// Fonts
$fredoka-font: "Fredoka", sans-serif;
$fraunces-font: "Fraunces", serif;

.bder-black-2 {
    border: 2px solid black;
}

.bg-red {
    background-color: $red;
}

.bg-red-medium-opacity {
    background-color: $red-medium-opacity;
}

.bg-blue {
    background-color: $blue;
}

.bg-blue-low-opacity {
    background-color: $blue-low-opacity;
}

.bder-blue-3 {
    border: 3px solid $blue;
}

.bg-green {
    background-color: $green;
}

.bg-yellow {
    background-color: $yellow;
}

.bg-yellow-medium-opacity {
    background-color: $yellow-medium-opacity;
}

.bg-yellow-low-opacity {
    background-color: $yellow-low-opacity;
}

.bder-yellow-3 {
    border: 3px solid $yellow;
}

.bder-yellow-low-opacity-3 {
    border: 3px solid $yellow-low-opacity;
}

.bder-yellow-3--hover:nth-child(1):hover {
    border-bottom: 3px solid $yellow;
    transition: .2s;
}

.bder-yellow-3--hover:nth-child(2):hover {
    border-bottom: 3px solid $yellow;
    transition: .2s;
}

.bder-yellow-3--hover:nth-child(3):hover {
    border-bottom: 3px solid $yellow;
    transition: .2s;
}

.bg-orange {
    background-color: $orange;
}

.bg-orange-medium-opacity {
    background-color: $orange-medium-opacity;
}

.bg-blue-red {
    background: rgb(132,191,185);
    background: -moz-linear-gradient(90deg, 
        rgba(132,191,185,1) 50%, 
        rgba(242,153,169,1) 50%
    );
    background: -webkit-linear-gradient(
        90deg, 
        rgba(132,191,185,1) 50%, 
        rgba(242,153,169,1) 50%
    );
    background: linear-gradient(
        90deg, 
        rgba(132,191,185,1) 50%, 
        rgba(242,153,169,1) 50%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(
        startColorstr=$blue-low-opacity,
        endColorstr=$red-low-opacity,
        GradientType=1
    );
}

.content-cards {
    padding: 9.9rem 9rem 0;
    padding-right: 8.5rem;
}

.py-content {
    padding: 9.9rem 8rem 0;
    padding-left: 9rem;
}

.py-top {
    padding-top: 9.9rem;
}

.content-centered {
    display: flex;
    align-items: center;
    justify-content: center;
}

.w-100 {
    width: 100%;
}

.h-100 {
    height: 100vh;
}

.loading {
    font-size: 2rem;
    font-weight: 650;
    font-family: $fraunces-font;
    gap: 1rem;
    background-color: $orange-low-opacity;
}

.flex-column {
    flex-direction: column;
}

.rainbow-text {
    display: inline-block;
    animation: rainbow 3s linear infinite;
    text-shadow:
        -1px -1px 0 gray,
        1px -1px 0 gray,
        -1px 1px 0 gray,
        1px 1px 0 gray
    ;
}

.fade-in {
    animation: fadeIn 0.5s forwards;
}

.fade-out {
    display: none;
    animation: fadeOut 0.5s forwards;
}

.right-bounce {
    animation: rightBounce 0.5s forwards;
}

.table-prods-w-75 {
    display: flex;
    width: 75%;
    height: 100%;
    align-items: center;

    #slct-prod {
        display: flex;
        min-width: 27px;
        min-height: 27px;
        margin-right: 10px;
        accent-color: $yellow;
    }
}

.table-prods-w-25 {
    display: flex;
    width: 25%;
    height: 100%;
    align-items: center;
}

// Rainbow text animation
@keyframes rainbow {
    0% {
        color: #FF0000;
    }
    16% {
        color: #FF7F00;
    }
    33% {
        color: #FFFF00;
    }
    50% {
        color: #00FF00;
    }
    66% {
        color: #0000FF;
    }
    83% {
        color: #4B0082;
    }
    100% {
        color: #9400D3;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes rightBounce {
    0% {
        opacity: 0;
        transform: translateX(1000px);
    }
    60% {
        opacity: 1;
        transform: translateX(-30px);
    }
    80% {
        transform: translateX(40px);
    }
    100% {
        transform: translateX(0);
    }
}


@keyframes bounceIn {
    0% {
        opacity: 0;
        transform: translateY(-1000px);
    }
    60% {
        opacity: 1;
        transform: translateY(30px);
    }
    80% {
        transform: translateY(-40px);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes bounceOut {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    20% {
        transform: translateY(-20px);
    }
    40% {
        opacity: 1;
        transform: translateY(10px);
    }
    60% {
        transform: translateY(-30px);
    }
    80% {
        transform: translateY(30px);
    }
    100% {
        transform: translateY(-1000px);
    }
}

// Responsive 1440px
@media (max-width: 1440px) {
    .content-cards {
        padding: 7.8rem 7rem 0;
        padding-right: 6.5rem;
    }

    .py-content {
        padding: 7.8rem 8rem 0;
    }
    
    .py-top {
        padding-top: 7.8rem;
    }

    .table-prods-w-75 {
        #slct-prod {
            display: flex;
            min-width: 24px;
            min-height: 24px;
        }
    }
}

// Responsive 1024px
@media (max-width: 1024px) {
    .content-cards {
        padding: 7.4rem 5.5rem 0;
        padding-right: 5rem;
    }
        
    .py-content {
        padding: 7.4rem 8rem 0;
    }
    
    .py-top {
        padding-top: 7.4rem;
    }

    .bg-blue-red {
        background: rgb(132,191,185);
        background: -moz-linear-gradient(180deg, 
            rgba(132,191,185,1) 50%, 
            rgba(242,153,169,1) 50%
        );
        background: -webkit-linear-gradient(
            180deg, 
            rgba(132,191,185,1) 50%, 
            rgba(242,153,169,1) 50%
        );
        background: linear-gradient(
            180deg, 
            rgba(132,191,185,1) 50%, 
            rgba(242,153,169,1) 50%
        );
        filter: progid:DXImageTransform.Microsoft.gradient(
            startColorstr=$blue-low-opacity,
            endColorstr=$red-low-opacity,
            GradientType=1
        );
    }
}

// Responsive 768px
@media (max-width: 768px) {
    .content-cards {
        padding: 7rem 3rem 0;
        padding-right: 2.5rem;
    }

    .py-content {
        padding: 7rem 8rem 0;
    }
    
    .py-top {
        padding-top: 7rem;
    }
}